import React, { useContext, useEffect, useState } from 'react';
import * as BsIcons from 'react-icons/bs';
import { SiWhatsapp } from 'react-icons/si';
import history from 'service/history';
import { helpYouValidation } from 'utils/validations';
import { virifyCpfIsValid } from 'utils/validCpf';
import mail from 'assets/icons/mail.svg';
import phone from 'assets/icons/phone.svg';
import Button from 'components/Buttons';
import Header from 'components/Header';
import Heading from 'components/Heading';
import InputField from 'components/InputField';
import RadioButton from 'components/RadioButton';
import TextFieldMoney from 'components/TextFieldMoney';
import SelectField from 'components/SelectField';
import Checkbox from 'components/Checkbox';
import Loading from 'components/Loading';
import ButtonConsult from './components/ButtonConsult';
import FoundedCPF from './components/FoundedCPF';
import useMoney from 'context/hooks/useMoney';
import useUserData from 'context/hooks/useUserData';
import useSteps from 'context/hooks/useSteps';
import { analyticsEvent } from 'service/analytics';
import appRdStationServices from 'api/api-rdstation/service';
import appdsvServices from 'api/api-appdsv/services';
import { Address } from 'context/models/address';
import useVehicule from 'context/hooks/useVehicule';
import * as CTX from '../../globalContext/globalContext';

import * as Yup from 'yup';
import appSellFluxServices from 'api/api-appsellflux/services';

import inputs from './inputs';
import * as Styled from './styles';

import { maskPhoneOrCel, unMaskPhoneValue, mask, unMask } from 'utils/mask';
import alphabeticalSort from 'utils/ordered';

import useAnalytics from 'context/hooks/useAnalytics';

type Values = {
	[key: string]: string | boolean | number;
};

type FieldErrors = {
	[key: string]: string;
};

interface ResponseCEPInformations {
	data: {
		cep: string;
		codigoEstado: string;
		endereco: string;
		nomeMunicipio: string;
		tipoLogradouroDescricao: string;
	};
}

const HelpYou = () => {
	const globalContext = useContext(CTX.Context);
	const {
		changeCompletedState,
		isCompletedState,
		getTipoOcupacao,
		listaTipoOcupacao
	} = useSteps();

	useEffect(() => {
		if (!isCompletedState(0)) {
			window.location.href = '/';
		}
	}, [isCompletedState]);

	const { setFieldsValue, checked, inputValues } = useVehicule();

	const [addressData, setAddressData] = useState<Address>({
		CEP: '',
		UF: '',
		address: '',
		city: '',
		publicPlace: '',
		check: false
	});

	const [cepError, setCepError] = useState('');

	const { getUserData, userData } = useUserData();
	const [loadingCep, setLoadingCep] = useState(false);
	const [emailErrorState, setEmailErrorState] = useState('');
	const [phoneErrorState, setPhoneErrorState] = useState('');
	const [preferenciasForm, setPreferenciasForm] = useState({
		preferenciaContatoWhatsapp: true,
		preferenciaContatoTelefone: true,
		preferenciaContatoEmail: true
	});

	const [emailState, setEmailState] = useState(globalContext.email as string);
	const [phoneState, setPhoneState] = useState(globalContext.phone as string);
	const { userValue, updateUserValue } = useMoney();

	const { params, analyticsValue, handleAddAnalyticsValue } = useAnalytics();
	const howMuchNeedYou = String(userValue);
	const [values, setValues] = useState<Values>({
		...userData,
		about: true,
		howMuchNeedYou: howMuchNeedYou
	});

	const [areThereErrors, setAreThereErrors] = useState<boolean>(false);
	const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

	useEffect(() => {
		getTipoOcupacao();
	}, [getTipoOcupacao]);

	const backRoute = () =>
		history.push({
			pathname: '/',
			search: location.search
		});

	useEffect(() => {
		const areThereErrorsResult =
			Object.keys(fieldErrors).length > 0 ||
			emailErrorState.length > 0 ||
			phoneErrorState.length > 0;
		setAreThereErrors(areThereErrorsResult);

		//console.log('fieldErrors --> ', fieldErrors);
	}, [fieldErrors, emailErrorState, phoneErrorState]);

	const handleChange = (name: string, value: string | boolean) => {
		setValues(oldState => ({ ...oldState, [name]: value }));
		if (name in fieldErrors) {
			const newFieldErrors = Object.assign({}, fieldErrors);
			delete newFieldErrors[name];
			setFieldErrors(newFieldErrors);
		}
	};

	const handleEmailChange = async (value: string) => {
		setEmailState(value);
		if (value.length > 0) {
			setEmailErrorState('');
		}
	};

	const handlePhoneChange = (value: string) => {
		setPhoneState(value);
		if (value.length > 0) {
			setPhoneErrorState('');
		}
	};

	const handleVehicleDataChange = (
		inputName: string,
		value: string | boolean | number
	) => {
		if (value === true) {
			if (inputName in fieldErrors) {
				const newFieldErrors = Object.assign({}, fieldErrors);
				delete newFieldErrors[inputName];
				setFieldErrors(newFieldErrors);
			}
		} else {
			setFieldErrors(oldState => ({
				...oldState,
				[inputName]: ''
			}));
		}

		setFieldsValue(inputName, value);
		setValues(oldState => ({ ...oldState, [inputName]: value }));
	};

	const emailSchema = Yup.string()
		.email('Digite um e-mail valido')
		.required('E-mail é obrigatório!');
	const emailValidation = (value: string) => {
		let emailValid = true;
		try {
			emailSchema.validateSync(value);
			if (emailValid) setEmailErrorState('');
		} catch (e) {
			if (e instanceof Yup.ValidationError) {
				emailValid = false;
				setEmailErrorState(e.message);
			} else throw e;
		}
		return emailValid;
	};

	const phoneSchema = Yup.string()
		.min(10, 'O numero de telefone deve ter no minimo 10 caracteres')
		.required('Telefone é obrigatório!');
	const phoneValidation = (value: string) => {
		let phoneValid = true;
		try {
			phoneSchema.validateSync(value);
			setPhoneErrorState('');
		} catch (e) {
			if (e instanceof Yup.ValidationError) {
				setPhoneErrorState(e.message);
				phoneValid = false;
			} else throw e;
		}
		return phoneValid;
	};

	const cpfValidation = (value: string) => {
		const cpfDesmasked = value.replaceAll('.', '').replaceAll('-', '');

		const cpfValid = virifyCpfIsValid(cpfDesmasked);

		if (!cpfValid)
			setFieldErrors(oldErrors => ({
				...oldErrors,
				cpf: 'Cpf inválido'
			}));

		return cpfValid;
	};

	const handleCEPOnClick = async () => {
		try {
			addressData.CEP = addressData.CEP.replace('-', '');
			const cepValidation = !!addressData.CEP && addressData.CEP.length === 8;

			if (cepValidation) {
				setCepError('');
			} else {
				const newForm = addressData;
				newForm.check = false;
				newForm.UF = '';
				newForm.address = '';
				newForm.city = '';
				newForm.publicPlace = '';

				setAddressData({ ...newForm });
				setCepError('CEP deve ter 8 dígitos!');
				return;
			}

			setLoadingCep(true);
			const response = await appdsvServices.getCEP<ResponseCEPInformations>(
				addressData.CEP.replace('-', '')
			);

			const newForm = addressData;
			newForm.check = !!response.data;
			newForm.UF = response.data.codigoEstado;
			newForm.address = response.data.endereco;
			newForm.city = response.data.nomeMunicipio;
			newForm.publicPlace = response.data.tipoLogradouroDescricao;

			setAddressData({ ...newForm });
			const analyticsBody = {
				cep: newForm.CEP,
				uf: newForm.UF,
				endereco: newForm.address,
				cidade: newForm.city,
				...analyticsValue
			};

			analyticsEvent('ck_consultar_cep', analyticsBody);

			handleAddAnalyticsValue(analyticsBody);
		} catch {
			const newForm = addressData;
			newForm.check = false;
			newForm.UF = '';
			newForm.address = '';
			newForm.city = '';
			newForm.publicPlace = '';

			setAddressData({ ...newForm });
		} finally {
			setLoadingCep(false);
		}
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const validEmail = emailValidation(emailState);
		const validPhone = phoneValidation(phoneState);
		const validCpf = cpfValidation(values?.cpf?.toString() ?? '');

		let errors = await helpYouValidation(values);

		//console.log('values --> ', values);
		if (!('isCar' in values)) {
			errors = { ...errors, ['isCar']: 'Opção obrigatória!' };
			//console.log('!(isCar in values) --> ', errors);
		}

		if (!('paidoff' in values)) {
			errors = { ...errors, ['paidoff']: 'Opção obrigatória!' };
			//console.log('!(paidoff in values)', errors);
		}

		if (errors || Number(userValue) < 5000) {
			if (Number(userValue) < 5000) {
				updateUserValue(5000);
				setFieldErrors(oldErrors => ({
					...oldErrors,
					howMuchNeedYou: 'Valor deve ser maior ou igual a R$ 5000'
				}));
			}
			setFieldErrors(oldErrors => ({ ...oldErrors, ...errors! }));
			return;
		}

		if (!validEmail || !validPhone || !validCpf) {
			return;
		}

		globalContext.setOccupation(String(values.occupation));
		globalContext.setCpfUsuario(values.cpf.toString());
		globalContext.setEmail(emailState);
		globalContext.setPhone(phoneState);

		globalContext.setUserCEP(addressData.CEP);
		globalContext.setUserUF(addressData.UF);
		globalContext.setUserCity(addressData.city);
		globalContext.setUserPublicPlace(addressData.publicPlace);
		globalContext.setUserAddress(addressData.address);
		globalContext.setUserAddressCheck(addressData.check);

		globalContext.setAbout(Boolean(values.about));
		globalContext.setPreferenciaContatoEmail(
			preferenciasForm.preferenciaContatoEmail
		);
		globalContext.setPreferenciaContatoTelefone(
			preferenciasForm.preferenciaContatoTelefone
		);
		globalContext.setPreferenciaContatoWhatsapp(
			preferenciasForm.preferenciaContatoWhatsapp
		);

		setFieldErrors({});
		getUserData(values);

		const body = {
			event_type: 'CONVERSION',
			event_family: 'CDP',
			payload: {
				conversion_identifier: 'financeira_cp',
				cf_etapa_lp: 'dados-pessoais-cp',
				name: values.fullname,
				email: emailState,
				personal_phone: phoneState,
				traffic_source: params.utm_source || '',
				traffic_medium: params.utm_medium || '',
				traffic_campaign: params.utm_campaign || ''
			}
		};

		const analyticsBody = {
			...analyticsValue,
			valor: values.howMuchNeedYou,
			emprestimo: values.reasonForLoan,
			nome: values.fullname,
			email: emailState,
			celular: phoneState,
			receberInformacoesProdutosServicos: values.about
		};

		const includeLeadBody = {
			name: values.fullname,
			email: emailState,
			phone: phoneState,
			url: 'https://financeira.gruposinosserra.com.br/help-you',
			tags: ['deixou-cadastro-help-you'],
			remove_tags: ['virou-lead-etapa-8']
		};

		const financingBody = {
			valorEmprestimo: values.howmuchNeedYou,
			nomeCliente: values.fullname,
			email: emailState,
			telefone: phoneState,
			motivoEmprestimo: values.reasonForLoan,
			receberInformacoesProdutosServicos: values.about,
			fonte: params.utm_source || '',
			midia: params.utm_medium || '',
			campanha: params.utm_campaign || '',
			etapa: 1
		};

		analyticsEvent('ck_avancar_primeira_etapa', analyticsBody);

		handleAddAnalyticsValue(analyticsBody);

		appdsvServices.postEmp(financingBody);
		appRdStationServices.postRdStation(body);
		appSellFluxServices.postIncludeLead(includeLeadBody);

		changeCompletedState(1, true);

		history.push({
			pathname: '/vehicle-data',
			search: location.search
		});
	};

	return (
		<Styled.Wrapper>
			<Header headerSize="large" />
			<Styled.Content>
				<Styled.InformativeText>
					<span className="bottom_text">
						Simule grátis um crédito utilizando seu carro como garantia:
					</span>
				</Styled.InformativeText>
				<form onSubmit={handleSubmit}>
					{inputs.map(input => (
						<React.Fragment key={input.id}>
							{input.type === 'textcurrency' && (
								<TextFieldMoney
									error={fieldErrors[input.name]}
									max={150000}
									id={input.id}
									name={input.name}
									helperText={input.helper}
									label={input.label}
									value={Number(userValue)}
									onChange={updateUserValue}
								/>
							)}
							{input.type === 'text' && (
								<InputField
									error={fieldErrors[input.name]}
									id={input.id}
									name={input.name}
									helperText={input.helper}
									label={input.label}
									value={String(values[input.name] || '')}
									onChange={event =>
										handleChange(input.name, event.target.value)
									}
								/>
							)}
							{input.type === 'cpf' && (
								<InputField
									error={fieldErrors[input.name]}
									id={input.id}
									name={input.name}
									helperText={input.helper}
									label={input.label}
									value={mask(
										String(values[input.name] || ''),
										'###.###.###-##',
										true
									)}
									onChange={event =>
										handleChange(
											input.name,
											unMask(event.target.value, '[^0-9]')
										)
									}
								/>
							)}
							{input.type === 'occupation' && (
								<SelectField
									error={fieldErrors[input.name]}
									id={input.id}
									name={input.name}
									options={alphabeticalSort(listaTipoOcupacao || []) || []}
									label={input.label}
									value={String(values[input.name] || '')}
									onValue={value => handleChange(input.name, value)}
								/>
							)}
							{input.type === 'autocomplete' && (
								<SelectField
									error={fieldErrors[input.name]}
									id={input.id}
									name={input.name}
									options={alphabeticalSort(input.options || []) || []}
									label={input.label}
									value={String(values[input.name] || '')}
									onValue={value => handleChange(input.name, value)}
								/>
							)}
							{input.type === 'phone' && (
								<InputField
									maxLength={15}
									error={fieldErrors[input.name]}
									id={input.id}
									name={input.name}
									helperText={input.helper}
									label={input.label}
									value={maskPhoneOrCel(String(values[input.name] || ''))}
									onChange={event =>
										handleChange(
											input.name,
											unMaskPhoneValue(event.target.value)
										)
									}
								/>
							)}
						</React.Fragment>
					))}

					<InputField
						error={emailErrorState}
						id={'email'}
						name={'email'}
						label={'Qual seu email?'}
						value={String(emailState || '')}
						onChange={event => handleEmailChange(event.target.value)}
					/>

					<InputField
						error={phoneErrorState}
						maxLength={15}
						id={'phone'}
						name={'phone'}
						label={'Número do celular'}
						value={maskPhoneOrCel(String(phoneState || ''))}
						onChange={event => handlePhoneChange(event.target.value)}
					/>

					<Styled.RadioContainer>
						<p>Seu veículo está quitado?</p>
						<RadioButton
							id="radio-two"
							name="paidoff"
							label="Sim. Está quitado."
							checked={inputValues.paidoff === true && true}
							defaultValue={String(inputValues.paidoff)}
							value={String(inputValues.paidoff)}
							onChange={() => handleVehicleDataChange('paidoff', true)}
						/>
						<RadioButton
							id="radio-one"
							name="paidoff"
							label="Ainda estou pagando."
							checked={inputValues.paidoff === false && true}
							defaultValue={String(inputValues.paidoff)}
							value={String(inputValues.paidoff)}
							onChange={() => handleVehicleDataChange('paidoff', false)}
						/>
						{fieldErrors['paidoff'] && (
							<Styled.Error>{fieldErrors['paidoff']}</Styled.Error>
						)}
						{checked === false && (
							<Styled.Error>
								<span>
									Carros já em financiamento, não podem ser usados como
									garantia.
								</span>
							</Styled.Error>
						)}
					</Styled.RadioContainer>
					{checked && (
						<Styled.HideInputGrid>
							<Styled.RadioContainer>
								<p>Seu veículo é um carro?</p>
								<RadioButton
									id="radio-two-isCar"
									name="isCar"
									label="Sim. É um carro."
									checked={inputValues.isCar === true && true}
									defaultValue={String(inputValues.isCar)}
									value={String(inputValues.isCar)}
									onChange={() => handleVehicleDataChange('isCar', true)}
								/>
								<RadioButton
									id="radio-one-isCar"
									name="isCar"
									label="Não. Não é um carro."
									checked={inputValues.isCar === false && true}
									defaultValue={String(inputValues.isCar)}
									value={String(inputValues.isCar)}
									onChange={() => handleVehicleDataChange('isCar', false)}
								/>
								{fieldErrors['isCar'] && (
									<Styled.Error>{fieldErrors['isCar']}</Styled.Error>
								)}
								{inputValues.isCar === false && (
									<Styled.Error>
										<span>
											Motos, caminhões e utilitários maiores não estão sendo
											aceitos.
										</span>
									</Styled.Error>
								)}
							</Styled.RadioContainer>
						</Styled.HideInputGrid>
					)}
					<Styled.WrapperCepError>
						<InputField
							maxLength={9}
							id={inputs[5].id}
							name={inputs[5].name}
							label={inputs[5].label}
							value={addressData.CEP.replace(/^(\d{5})(\d{3})/, '$1-$2')}
							onChange={event =>
								setAddressData({ ...addressData, CEP: event.target.value })
							}
						/>
						<span>{cepError}</span>
					</Styled.WrapperCepError>

					<ButtonConsult onClick={handleCEPOnClick} type="button">
						Consultar
					</ButtonConsult>

					{loadingCep && <Loading message="Estamos verificando seu CEP" />}

					{!loadingCep && (
						<FoundedCPF
							street={`${addressData.publicPlace ?? ''} ${
								addressData.address ?? ''
							}`}
							UF={addressData.UF}
							city={addressData.city}
							check={addressData.check}
						/>
					)}

					<Styled.OptionalAcceptedWrapper>
						<Heading>Preferências de contato</Heading>
						<Styled.OptionalAccepted>
							<Checkbox
								column
								id="acceptWhatsapp"
								name="whatsapp"
								value={preferenciasForm.preferenciaContatoWhatsapp}
								onCheck={value =>
									setPreferenciasForm(oldForm => ({
										...oldForm,
										preferenciaContatoWhatsapp: value
									}))
								}
							>
								<span className="green">
									<SiWhatsapp size={12} /> WhatsApp.
								</span>
							</Checkbox>
							<Checkbox
								column
								id="acceptPhone"
								name="phone"
								value={preferenciasForm.preferenciaContatoTelefone}
								onCheck={value =>
									setPreferenciasForm(oldForm => ({
										...oldForm,
										preferenciaContatoTelefone: value
									}))
								}
							>
								<span>
									<img src={phone} alt="" /> Telefone
								</span>
							</Checkbox>
							<Checkbox
								column
								id="acceptedEmail"
								name="mail"
								value={preferenciasForm.preferenciaContatoEmail}
								onCheck={value =>
									setPreferenciasForm(oldForm => ({
										...oldForm,
										preferenciaContatoEmail: value
									}))
								}
							>
								<span>
									<img src={mail} alt="" /> E-mail
								</span>
							</Checkbox>
						</Styled.OptionalAccepted>
					</Styled.OptionalAcceptedWrapper>
					<Styled.ButtonWrapper>
						<Button type="submit" disabled={areThereErrors}>
							Continuar
							<BsIcons.BsArrowRight size={24} />
						</Button>
						<Button themeBtn="secondary" onClick={backRoute}>
							<BsIcons.BsArrowLeft size={24} />
						</Button>
					</Styled.ButtonWrapper>
					<Styled.About>
						<Checkbox
							id="About"
							name="about"
							value={Boolean(values.about)}
							onCheck={value =>
								setValues(oldState => ({ ...oldState, about: value }))
							}
						>
							Saber mais sobre o Grupo Sinosserra.
						</Checkbox>
						<p>(informações de produtos e serviços)</p>
					</Styled.About>
				</form>
			</Styled.Content>
		</Styled.Wrapper>
	);
};

export default HelpYou;
