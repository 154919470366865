import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import * as BsIcons from 'react-icons/bs';

import useAnalytics from 'context/hooks/useAnalytics';
import useMoney from 'context/hooks/useMoney';

import Button from 'components/Buttons';
import Header from 'components/Header';
import InputMoney from 'components/InputMoney';
import LandingPage from 'components/LandingPage';

import history from 'service/history';
//import { analyticsEvent } from 'service/analytics';

import * as Styled from './styles';
import useUserData from 'context/hooks/useUserData';
import useVehicule from 'context/hooks/useVehicule';
import useSteps from 'context/hooks/useSteps';
import { useContext } from 'react';
import * as CTX from '../../globalContext/globalContext';
import appdsvServices from 'api/api-appdsv/services';
import appRdStationServices from 'api/api-rdstation/service';

const Home = () => {
	const { userValue, updateUserValue } = useMoney();
	const { clearUserData } = useUserData();
	const { clearVehiculeData } = useVehicule();
	const [error, setError] = useState('');
	const { changeCompletedState } = useSteps();
	const globalContext = useContext(CTX.Context);

	const location = useLocation();

	const { setParams, params, analyticsValue, handleAddAnalyticsValue } =
		useAnalytics();

	useEffect(() => {
		setParams(getQueryStringParams(location?.search) || {});
	}, []);

	const getQueryStringParams = (query: any) => {
		return query
			? (/^[?#]/.test(query) ? query.slice(1) : query)
					.split('&')
					.reduce((params: any, param: any) => {
						const [key, value] = param.split('=');
						params[key] = value
							? decodeURIComponent(value.replace(/\+/g, ' '))
							: '';
						return params;
					}, {})
			: {};
	};

	const handleClick = () => {
		if (globalContext.cpfUsuario && globalContext.cpfUsuario.length !== 11) {
			window.alert('Credenciais na URL incompativel.');
			return;
		}
		const bodyAnalytics = {
			...analyticsValue,
			valorInicial: userValue,
			traffic_source: params.utm_source || '',
			traffic_medium: params.utm_medium || '',
			traffic_campaign: params.utm_campaign || ''
		};

		//analyticsEvent('ck_simular', bodyAnalytics);

		handleAddAnalyticsValue(bodyAnalytics);

		if (Number(userValue) < 5000) {
			setError('Valor deve estar entre R$ 5.000 e R$ 150.000');
			updateUserValue(5000);
			return;
		}
		location.search = '';

		setError('');

		changeCompletedState(0, true);

		history.push({
			pathname: '/help-you',
			search: location.search
		});
	};

	useEffect(() => {
		clearUserData();
		clearVehiculeData();
	}, []);

	useEffect(() => {
		const queryParams = location.search;
		if (queryParams.match('ep=')) {
			const paramsDivision = queryParams.split('ep=')[1];
			const paramsResult = paramsDivision.split('&uc=');
			globalContext.setEmpresaId(paramsResult[0]);
			globalContext.setCpfUsuario(paramsResult[1]);

			if (paramsResult[1].length === 11) {
				const body = {
					empresaId: paramsResult[0],
					cpfUsuario: paramsResult[1]
				};

				appdsvServices
					.postEmp(body)
					.then(() => console.log('Enviado os dados com sucesso.'))
					.catch(erro => {
						console.log(erro);
					});
			}
		}
	}, []);

	return (
		<>
			<React.Fragment>
				<Styled.Wrapper>
					<Header headerSize="small" translucent></Header>
					<Styled.Container>
						<Styled.InformativeText>
							<span className="white_text">Seu carro vale</span>
							<br />
							<span className="bold_text">Dinheiro</span>
							<br />
							<span className="bottom_text">
								Crédito com garantia em veículo
							</span>
							<Styled.SubText>
								<span className="subtext">
									Conheça agora <br /> o crédito com as <br />
									<strong> melhores taxas</strong> <br /> do mercado!
								</span>
							</Styled.SubText>
						</Styled.InformativeText>

						<Styled.SimulationArea>
							<span className="info_text">De quanto você precisa?</span>
							<Styled.InputWrapper>
								<InputMoney
									error={error}
									onValue={updateUserValue}
									id="money"
									name="money"
									initialValue={5000}
									max={150000}
									value={userValue}
								/>
							</Styled.InputWrapper>
							<Styled.Footer>
								<Button onClick={handleClick} themeBtn="primary">
									Simular
								</Button>
								<Styled.KnowMore>
									<a href="#landingpage-container">Saiba mais</a>
									<BsIcons.BsArrowDown size={16} />
								</Styled.KnowMore>
							</Styled.Footer>
						</Styled.SimulationArea>
					</Styled.Container>
				</Styled.Wrapper>
				<LandingPage />
			</React.Fragment>
		</>
	);
};

export default Home;
